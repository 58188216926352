import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
// import BlogPostPreview from "../Blog/BlogPostPreview";
import GraphQLErrorList from "../Blog/graphql-error-list";
import {
  mapEdgesToNodes,
  getBlogUrl,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
} from "../../lib/helpers";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonWithUnderline from "../Button/ButtonWithUnderline";

const RecentBlogPosts = ({ props, className, category }) => {
  const data = useStaticQuery(graphql`
    {
      posts: allSanityPost(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
          }
        }
      }
      image: file(relativePath: { eq: "repeating/blogs/image.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
      numberOne: file(relativePath: { eq: "repeating/blogs/1 outlined.svg" }) {
        publicURL
      }
      numberTwo: file(relativePath: { eq: "repeating/blogs/2 outlined.svg" }) {
        publicURL
      }
      numberThree: file(
        relativePath: { eq: "repeating/blogs/3 outlined.svg" }
      ) {
        publicURL
      }
    }
  `);

  const errors = props;

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  let postNodes = [];

  category
    ? (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
            .filter((items) =>
              items.categories.find((item) => item.title === category)
            )
        : [])
    : (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : []);

  return (
    <>
      {postNodes && postNodes.length > 0 && (
        <section className={` ${className || "pb-20 md:pb-32"}`}>
          <div className="container">
            <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
              <div>
                <GatsbyImage
                  image={data.image.childImageSharp.gatsbyImageData}
                />
              </div>
              <div>
                <div className="flex items-center justify-between mb-10 md:mb-14">
                  <h2 className="mb-0">JBP Blog</h2>
                  <ButtonWithUnderline
                    href="/blog/"
                    text="Read All Articles"
                    className="hidden md:inline-flex"
                  />
                </div>
                {postNodes && (
                  <div className="border-t border-gray-100 mb-10 md:mb-0">
                    {postNodes.slice(0, 3).map((node, i) => (
                      <div
                        key={node.id}
                        className="pt-7 md:pt-10 pb-3 md:pb-7 border-b border-gray-100"
                      >
                        <AniLink
                          fade
                          to={getBlogUrl(node.slug.current)}
                          className="no-underline flex items-start space-x-4 md:space-x-8"
                        >
                          {i === 0 ? (
                            <img src={data.numberOne.publicURL} alt="One" />
                          ) : i === 1 ? (
                            <img src={data.numberTwo.publicURL} alt="Two" />
                          ) : i === 2 ? (
                            <img src={data.numberThree.publicURL} alt="Three" />
                          ) : null}

                          <div className="group">
                            {/* {node.featuredImage && node.featuredImage.asset && (
                              <div className="overflow-hidden mb-6">
                                <GatsbyImage
                                  image={
                                    node.featuredImage.asset.gatsbyImageData
                                  }
                                  className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                                />
                              </div>
                            )} */}
                            <div>
                              <div className="font-body text-xl font-normal text-primary-600 mb-2">
                                {node.title}
                              </div>

                              {node.categories && (
                                <ul className="flex">
                                  {node.categories.map((category, i) => (
                                    <li
                                      className="font-body text-gray-300 tracking-wide uppercase mb-2"
                                      key={i}
                                    >
                                      {i > 0
                                        ? `, ${category.title}`
                                        : category.title}
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {/* {node._rawExcerpt && (
          <div>
            <PortableText blocks={node._rawExcerpt} />
          </div>
        )} */}
                              {/* <div>{format(new Date(node.publishedAt), "MMMM d, yyyy")}</div> */}
                            </div>
                          </div>
                        </AniLink>
                      </div>
                    ))}
                  </div>
                )}
                <ButtonWithUnderline
                  href="/blog/"
                  text="Read All Articles"
                  className="md:hidden"
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default RecentBlogPosts;
