import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { BgImage } from "gbimage-bridge";
// import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import { mapEdgesToNodes } from "../../lib/helpers";
import Slider from "../Slider/SliderStandard";

const StyledSlider = styled.div`
  .slick-prev,
  .slick-next {
    ${tw`text-primary-600 text-3xl !top-8`}
    &:hover {
      ${tw`text-primary-800`}
    }
  }
  .slick-prev {
    ${tw`left-0`}
  }
  .slick-next {
    ${tw`right-0`}
  }
  .slick-dots {
    ${tw`relative mt-7 md:mt-0 flex! justify-center items-center space-x-3`}
    li {
      ${tw`h-2 w-2 rounded-full bg-primary-600/40 hover:bg-primary-600 mr-0 transition-colors duration-300 ease-linear`}
      &.slick-active {
        ${tw`bg-primary-600`}
      }
    }
  }
`;

const Testimonial = ({ className, headingLevel, category }) => {
  const data = useStaticQuery(graphql`
    {
      backgroundDesktop: file(
        relativePath: { eq: "repeating/testimonials/Background Desktop.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      testimonials: allSanityTestimonial(
        sort: { fields: [order], order: ASC }
      ) {
        edges {
          node {
            id
            testimonial
            name
            featured
            categories {
              title
            }
          }
        }
      }
    }
  `);

  let testimonialsNodes = [];

  category
    ? (testimonialsNodes = (data || {}).testimonials
        ? mapEdgesToNodes(data.testimonials).filter((items) =>
            items.categories.find((item) => item.title === category)
          )
        : [])
    : (testimonialsNodes = (data || {}).testimonials
        ? mapEdgesToNodes(data.testimonials).filter((items) => items.featured)
        : []);

  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`pb-20 md:pb-32 ${className}`}>
      <BgImage
        image={data.backgroundDesktop.childImageSharp.gatsbyImageData}
        className="py-32 md:py-60 lg:py-80"
      />

      <div className="container">
        <div className="bg-white text-center pt-6 md:pt-16 px-6 md:px-14 pb-6 md:pb-18 -mt-36 md:-mt-40 lg:-mt-64 relative z-10">
          <header className="mb-8 md:mb-12">
            <HeadingTag className="text-[#26272B]">
              We’ve Helped Others Like You
            </HeadingTag>
          </header>

          <StyledSlider className="relative">
            <Slider>
              {testimonialsNodes.map((testimonial, i) => {
                return (
                  <div key={i}>
                    <blockquote className="md:max-w-[800px] mx-auto md:px-8">
                      <q className="text-gray-600 before:hidden">
                        {testimonial.testimonial}
                      </q>
                      <footer className="mt-8 md:mt-10 md:mb-16 mx-auto">
                        <div>
                          <cite className="not-italic">
                            <div className="text-[#26272B] font-semibold">
                              <span>{testimonial.name}</span>
                            </div>
                            {/* <GatsbyImage image={testimonial.platform} /> */}
                          </cite>
                        </div>
                      </footer>
                    </blockquote>
                  </div>
                );
              })}
            </Slider>
          </StyledSlider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
