import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { mapEdgesToNodes } from "../../lib/helpers";
import SliderProgressBar from "../Slider/SliderProgressBar";
import ButtonWithUnderline from "../Button/ButtonWithUnderline";

const Attorneys = ({ className, headingLevel, category }) => {
  const data = useStaticQuery(graphql`
    {
      attorneys: allSanityTeamMember(
        sort: { fields: [order], order: ASC }
        filter: {
          categories: {
            elemMatch: {
              title: { in: ["Shareholder Attorneys", "Associate Attorneys", "Law Clerks", "Paralegals"] }
            }
          }
        }
      ) {
        edges {
          node {
            id
            name
            slug {
              current
            }
            seoTitle
            metaDescription
            headshot {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 564)
              }
            }
            title
            categories {
              title
            }
          }
        }
      }
    }
  `);

  let attorneysNodes = [];

  category
    ? (attorneysNodes = (data || {}).attorneys
        ? mapEdgesToNodes(data.attorneys).filter((items) =>
            items.categories.find((item) => item.title === category)
          )
        : [])
    : (attorneysNodes = (data || {}).attorneys
        ? mapEdgesToNodes(data.attorneys)
        : []);

  const HeadingTag = headingLevel || "h2";

  return (
    attorneysNodes.length > 0 && (
      <section className={`pb-20 md:pb-32 ${className}`}>
        <div className="container">
          <div className="flex items-start justify-between mb-4 md:mb-8">
            <div>
              <HeadingTag>Our Colorado Attorneys</HeadingTag>
            </div>

            <ButtonWithUnderline
              href="/our-team/"
              text="Meet the Team"
              className="w-full md:w-auto hidden md:inline-flex"
            />
          </div>

          <SliderProgressBar slideCount={attorneysNodes.length}>
            {attorneysNodes.map((item, i) => (
              <div key={i}>
                <AniLink
                  fade
                  to={`/our-team/${item.slug.current}/`}
                  className="group no-underline"
                >
                  <div className="overflow-hidden mb-3.5">
                    <GatsbyImage
                      image={item.headshot.asset.gatsbyImageData}
                      className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                    />
                  </div>
                  <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                    {item.name}
                  </div>
                  <div className="text-primary-50 text-sm mb-0">
                    {item.title}
                  </div>
                </AniLink>
              </div>
            ))}
          </SliderProgressBar>
        </div>
      </section>
    )
  );
};

export default Attorneys;
