import React, { useRef, useState } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Slick from "react-slick";
import styled from "@emotion/styled";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled.div`
  .slick-track {
    ${tw`ml-0`}
  }
  .slick-slide > div {
    ${tw`mx-3`}
  }
  .slick-list {
    ${tw`-mx-3 p-0!`}
  }
  .slick-prev,
  .slick-next {
    ${tw`text-primary-600 text-xl`}
    &:hover {
      ${tw`text-primary-50`}
    }
  }
  .slick-prev {
    ${tw`xl:-left-8 2xl:-left-14`}
  }
  .slick-next {
    ${tw`xl:-right-8 2xl:-right-14`}
  }
`;

const Slider = ({ children, slideCount }) => {
  const slider = useRef();

  const [progressWidth, setProgressWidth] = useState(0);

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="group slick-prev"
        aria-label="Previous Slide"
      >
        <i className="far fa-chevron-left text-primary-600"></i>
      </button>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="group slick-next"
        aria-label="Next Slide"
      >
        <i className="far fa-chevron-right text-primary-600"></i>
      </button>
    );
  }

  const sliderSettings = {
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 7500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: slideCount > 4,
    swipeToSlide: true,
    // beforeChange: (next) => setProgressWidth((next / (slideCount - 1)) * 100),
    afterChange: (next) => setProgressWidth((next / (slideCount - 1)) * 100),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <StyledSlider className="relative">
      <Slick {...sliderSettings} ref={slider}>
        {children}
      </Slick>

      <div
        className={`relative progress bg-gray-100 w-full h-1 mt-10 md:mt-14 ${
          slideCount < 4 && "md:hidden"
        } `}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          className="absolute left-0 top-0 bg-primary-600 h-1 transition-all duration-300 ease-linear"
          style={{ width: progressWidth + "%" }}
        />
      </div>
    </StyledSlider>
  );
};

export default Slider;
